<template>
  <div>
    <TheHeader />
    <div class="columns">
      <nav class="sidebar is-narrow hero is-fullheight">
        <TheSidebar />
      </nav>
      <main class="column">
        <slot />
      </main>
    </div>
  </div>
</template>

<style scoped>
main {
  /* FIXME: はみ出し防止の暫定措置 */
  max-width: 90%;
}

.columns {
  margin: 0;
}

.sidebar {
  border-right: 1px solid hsl(0, 0%, 90%);
}

.overlay {
  position: absolute;
  width: 100%;
  z-index: 1000;
  opacity: 0.8;
}
</style>
